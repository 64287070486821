<template>

<div class="text-left no-print">
  <v-menu ref="menu" v-model="active" offset-x min-width="300px" min-height="300px" :close-on-content-click="false" class="no-print">

      <template v-slot:activator="{ on: { click }, attrs }">
        <v-chip @click="getDados" :color="getColor(item.status)" dark v-bind="attrs"> 
          {{ item.status === 'B' ? 'Baixado' : 'Não Baixado'}}
        </v-chip>
      </template>

      <v-card class="card pb-5" v-if="!loading">
        <v-card-title class="card-title"> ({{item.entity.cod }}) - {{ item.name}}  </v-card-title>
        <v-card-text class="card-sub pb-1"> {{ item.pendingOperationTypeName }} {{ bugParams(item.params)}}  </v-card-text>

        <template v-if="item.pendingOperationTypeId === 'LCX'">
          <template v-for="(v, k) in item.params.data">
            <v-card-text class="card-t-title pb-0 pt-2" :key="`${k}-tipo`"> {{ nameEntry(v.entryType.id) }}   </v-card-text>
            <hr class="ml-4  mr-4" :key="`${k}-linha1`" />

            <v-card-text class="card-t pb-0 pt-1" :key="`${k}-type`"> Tipo:
              <span :style="{ color: typeEntries(v.entryType.id) === 'C' ? 'green !important' : 'red !important' }">
                {{ typeEntries(v.entryType.id) === 'C' ? 'Crédito': 'Débito' }} 
              </span>
                 
            </v-card-text>
            <hr class="ml-4  mr-4" :key="`${k}-linha2`" />
            

            <v-card-text class="card-t pb-0 pt-1" :key="`${k}-revenda`"> REV: <span> {{ codRevenda(v.entity.id) }} </span>   </v-card-text>
            <hr class="ml-4  mr-4" :key="`${k}-linha3`" />

            <v-card-text class="card-t pb-0 pt-1" :key="`${k}-valor`"> Valor: 
              <span :style="{ color: typeEntries(v.entryType.id) === 'C' ? 'green !important' : 'red !important' }" > 
               {{ typeEntries(v.entryType.id) === 'C' ? v.value : v.value * -1 | currency }} </span> 
               
            </v-card-text>
            <!-- <hr class="ml-4 mb-3 mr-4" :key="`${k}-linha3`" /> -->
          </template>

        </template>

      </v-card>
      <div class="d-flex loader" v-else>
          <v-progress-circular  indeterminate color="primary" />
      </div>
    </v-menu>
  </div>

</template>

<script>
// eslint-disable-next-line
import moment from 'moment'
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapActions, mapGetters } from 'vuex'
import { uniq, size } from 'lodash'
moment.locales('pt-br')
export default {
  name: 'FinanceiroTooltipLancamento',
  components: {
  },
  props:['item', 'id'],

  data: () => ({
    loadingRelatorio: false,
    deleted: {},
    active: false,
    loading: false
  }),

  mounted () {},
  computed: {
    ...mapGetters('entities', ['listaItens']),
    ...mapGetters('entryTypes', ['listaPaths']),
    variables: () => variables,
    entidades () {
      let dados = this.item
      if (dados.pendingOperationTypeId !== 'LCX') return false
      return uniq(this.item.params.data.map(v => v.entity.id )).join(',')
    }
  },
  methods: {
    ...mapActions('entities', ['getListEntitiesTreeLancamentoFinanceiro']),
    bugParams (v) {
      if (size(v) !== 0 && v.channel) return '- Terminal'
      return
    },
    formatValue (v) {
      return v * -1
    },
    nameEntry (entryType) {
      let name = this.listaPaths.filter(v => v.id === entryType)[0]
      if (!name) return null
      let prefixo = 'Caixa'
      if (name.parentPath.indexOf('A.R.PGTR') !== -1) {
        prefixo = 'Pagamento'
      }

      return `${prefixo}: ${name.description}`
    },
    typeEntries (entryType) {
      let name = this.listaPaths.filter(v => v.id === entryType)[0]
      if (!name) return null
      return name.type
    },
    codRevenda (id) {
      let cod = this.listaItens.filter(v => v.id === id)[0]
      if (!cod) return null
      return `(${cod.cod}) - ${cod.name}`
    },
    async getDados () {
      const vm = this
      vm.active = true
      vm.loading = true

      if (!vm.entidades) {
        vm.active = true
        vm.loading = false
        return
      }
      this.getListEntitiesTreeLancamentoFinanceiro({
      _filter: { 'id_In': this.entidades },
        pageSize: 50,
        order: {'cod::int': 'ASC'}
      }).finally(() => { this.loading = false })
    },
    getColor (status) {
      return status === 'B' ? this.variables.colorSecondary : this.variables.colorPrimary
    },
    async openModal (name, data) {
      if (data.id !== this.id) return
      const vm = this
      vm.loadingRelatorio = true
      vm.loadingRelatorio = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/helpers/variables";
.loader {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  background: white;
}
.card {
  &-title {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 0;
  }
  &-sub {
    font-weight: normal;
    font-size: 12px;
    color: #9797ad !important;
  }
  &-t {
    color: #9797ad !important;
    &-title {
      color:#9797ad;
      font-size: 16px;
      font-weight: bold;
    }
    &-type {
      height: 25px;
    }
    span {
      color: #525252;
      font-size: 14px;
    }
  }
  &-links {
    display: block;
    width: 100%;
    background: #efefef;
    padding: 15px 0;
    font-size: 14px;
    &-text{
      color: #5076ff;
      span {
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        &:hover{
          color: #708efc;
        }
      }
      span.ex:hover{
          color: red !important;
        
      }
    }
  }
}

</style>